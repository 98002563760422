import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InquiryStatus } from 'src/app/models/inquiry';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { CardBadgeComponent } from 'src/app/components/shared/card-badge/card-badge.component';
import { StatusColors } from 'src/app/data/enums';

type StatusStyle = {
  color: string;
  name: string;
};

export const inquiryStatusMap: Record<InquiryStatus, StatusStyle> = {
  DRAFT: { name: 'entwurf', color: StatusColors.DRAFT },

  OPEN: { name: 'offen', color: StatusColors.ACTIVE },

  OFFERS: { name: 'angebote erhalten', color: StatusColors.ATTENTION },

  // Not Editable
  VERIFIED: {
    name: 'überprüft',
    color: StatusColors.PENDING,
  },
  SAMPLE_PENDING: {
    name: 'probenahme',
    color: StatusColors.PENDING,
  },

  // Done
  RETRACTED: { name: 'zurückgezogen', color: StatusColors.INACTIVE },
  TIMED_OUT: { name: 'abgelaufen', color: StatusColors.INACTIVE },
  FINISHED: { name: 'abgeschlossen', color: StatusColors.INACTIVE },
  FINISHED_PARTLY: {
    name: 'teilweise angenommen',
    color: StatusColors.INACTIVE,
  },
};

@Component({
  selector: 'app-inquiry-status-badge',
  standalone: true,
  templateUrl: './inquiry-status-badge.component.html',
  styleUrls: ['./inquiry-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, AppMaterialModule, CardBadgeComponent],
})

// https://mycolor.space/?hex=%235C1249&sub=1
export class InquiryStatusBadgeComponent {
  status = input<InquiryStatus>();
  offers = input<number>();

  statusStyle = computed(() => {
    const currentStatus = this.status();
    if (this.offers() > 0 && currentStatus !== 'FINISHED') {
      return inquiryStatusMap.OFFERS;
    } else {
      return inquiryStatusMap[currentStatus];
    }
  });
}
